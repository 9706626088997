import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeComponent from './pages/HomeComponent';
import DigitalConfirmComponent from './pages/DigitalConfirmComponent';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:slug" element={<DigitalConfirmComponent />} />
      </Routes>
    </Router>
  );
};

export default App;
